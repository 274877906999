import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step beta ca provisioner update`}</strong>{` -- update a provisioner`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step beta ca provisioner update <name> [--public-key=<file>]
[--private-key=<file>] [--create] [--password-file=<file>]
[--admin-cert=<file>] [--admin-key=<file>] [--admin-provisioner=<name>]
[--admin-subject=<subject>] [--password-file=<file>] [--ca-url=<uri>]
[--root=<file>] [--context=<name>]

ACME

step beta ca provisioner update <name> [--force-cn] [--require-eab] [--disable-eab]
[--admin-cert=<file>] [--admin-key=<file>] [--admin-provisioner=<name>]
[--admin-subject=<subject>] [--password-file=<file>] [--ca-url=<uri>]
[--root=<file>] [--context=<name>]

OIDC

step beta ca provisioner update <name>
[--client-id=<id>] [--client-secret=<secret>]
[--configuration-endpoint=<url>] [--listen-address=<address>]
[--domain=<domain>] [--remove-domain=<domain>]
[--group=<group>] [--remove-group=<group>]
[--admin=<email>]... [--remove-admin=<email>]...
[--admin-cert=<file>] [--admin-key=<file>] [--admin-provisioner=<name>]
[--admin-subject=<subject>] [--password-file=<file>] [--ca-url=<uri>]
[--root=<file>] [--context=<name>]

X5C

step beta ca provisioner update <name> --x5c-root=<file>
[--admin-cert=<file>] [--admin-key=<file>] [--admin-provisioner=<name>]
[--admin-subject=<subject>] [--password-file=<file>] [--ca-url=<uri>]
[--root=<file>] [--context=<name>]

Kubernetes Service Account

step beta ca provisioner update <name> [--public-key=<file>]
[--admin-cert=<file>] [--admin-key=<file>] [--admin-provisioner=<name>]
[--admin-subject=<subject>] [--password-file=<file>] [--ca-url=<uri>]
[--root=<file>] [--context=<name>]

IID (AWS/GCP/Azure)

step beta ca provisioner update <name>
[--aws-account=<id>]... [--remove-aws-account=<id>]...
[--gcp-service-account=<name>]... [--remove-gcp-service-account=<name>]...
[--gcp-project=<name>]... [--remove-gcp-project=<name>]...
[--azure-tenant=<id>] [--azure-resource-group=<name>] [--azure-subscription-id=<id>] [--azure-object-id=<id>]
[--instance-age=<duration>] [--iid-roots=<file>]
[--disable-custom-sans] [--disable-trust-on-first-use]
[--admin-cert=<file>] [--admin-key=<file>] [--admin-provisioner=<name>]
[--admin-subject=<subject>] [--password-file=<file>] [--ca-url=<uri>]
[--root=<file>] [--context=<name>]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step ca provisioner update`}</strong>{` updates a provisioner in the CA configuration.`}</p>
    <p>{`WARNING: The 'beta' prefix is deprecated and will be removed in a future release.
Please use 'step ca admin ...' going forwards.`}</p>
    <h2>{`Positional arguments`}</h2>
    <p><inlineCode parentName="p">{`name`}</inlineCode>{`
The name of the provisioner.`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--name`}</strong>{`=`}<inlineCode parentName="p">{`name`}</inlineCode>{`
The new `}<inlineCode parentName="p">{`name`}</inlineCode>{` for the provisioner.`}</p>
    <p><strong parentName="p">{`--x509-template`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The x509 certificate template `}<inlineCode parentName="p">{`file`}</inlineCode>{`, a JSON representation of the certificate to create.`}</p>
    <p><strong parentName="p">{`--x509-template-data`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The x509 certificate template data `}<inlineCode parentName="p">{`file`}</inlineCode>{`, a JSON map of data that can be used by the certificate template.`}</p>
    <p><strong parentName="p">{`--ssh-template`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The x509 certificate template `}<inlineCode parentName="p">{`file`}</inlineCode>{`, a JSON representation of the certificate to create.`}</p>
    <p><strong parentName="p">{`--ssh-template-data`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The ssh certificate template data `}<inlineCode parentName="p">{`file`}</inlineCode>{`, a JSON map of data that can be used by the certificate template.`}</p>
    <p><strong parentName="p">{`--x509-min-dur`}</strong>{`=`}<inlineCode parentName="p">{`duration`}</inlineCode>{`
The minimum `}<inlineCode parentName="p">{`duration`}</inlineCode>{` for an x509 certificate generated by this provisioner.`}</p>
    <p><strong parentName="p">{`--x509-max-dur`}</strong>{`=`}<inlineCode parentName="p">{`duration`}</inlineCode>{`
The maximum `}<inlineCode parentName="p">{`duration`}</inlineCode>{` for an x509 certificate generated by this provisioner.`}</p>
    <p><strong parentName="p">{`--x509-default-dur`}</strong>{`=`}<inlineCode parentName="p">{`duration`}</inlineCode>{`
The default `}<inlineCode parentName="p">{`duration`}</inlineCode>{` for an x509 certificate generated by this provisioner.`}</p>
    <p><strong parentName="p">{`--ssh-user-min-dur`}</strong>{`=`}<inlineCode parentName="p">{`duration`}</inlineCode>{`
The minimum `}<inlineCode parentName="p">{`duration`}</inlineCode>{` for an ssh user certificate generated by this provisioner.`}</p>
    <p><strong parentName="p">{`--ssh-user-max-dur`}</strong>{`=`}<inlineCode parentName="p">{`duration`}</inlineCode>{`
The maximum `}<inlineCode parentName="p">{`duration`}</inlineCode>{` for an ssh user certificate generated by this provisioner.`}</p>
    <p><strong parentName="p">{`--ssh-user-default-dur`}</strong>{`=`}<inlineCode parentName="p">{`duration`}</inlineCode>{`
The maximum `}<inlineCode parentName="p">{`duration`}</inlineCode>{` for an ssh user certificate generated by this provisioner.`}</p>
    <p><strong parentName="p">{`--ssh-host-min-dur`}</strong>{`=`}<inlineCode parentName="p">{`duration`}</inlineCode>{`
The minimum `}<inlineCode parentName="p">{`duration`}</inlineCode>{` for an ssh host certificate generated by this provisioner.`}</p>
    <p><strong parentName="p">{`--ssh-host-max-dur`}</strong>{`=`}<inlineCode parentName="p">{`duration`}</inlineCode>{`
The maximum `}<inlineCode parentName="p">{`duration`}</inlineCode>{` for an ssh host certificate generated by this provisioner.`}</p>
    <p><strong parentName="p">{`--ssh-host-default-dur`}</strong>{`=`}<inlineCode parentName="p">{`duration`}</inlineCode>{`
The maximum `}<inlineCode parentName="p">{`duration`}</inlineCode>{` for an ssh host certificate generated by this provisioner.`}</p>
    <p><strong parentName="p">{`--disable-renewal`}</strong>{`
Disable renewal for all certificates generated by this provisioner.`}</p>
    <p><strong parentName="p">{`--allow-renewal-after-expiry`}</strong>{`
Allow renewals for expired certificates generated by this provisioner.`}</p>
    <p><strong parentName="p">{`--x509`}</strong>{`
Enable provisioning of x509 certificates.`}</p>
    <p><strong parentName="p">{`--ssh`}</strong>{`
Enable provisioning of ssh certificates.`}</p>
    <p><strong parentName="p">{`--create`}</strong>{`
Create the JWK key pair for the provisioner.`}</p>
    <p><strong parentName="p">{`--private-key`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The `}<inlineCode parentName="p">{`file`}</inlineCode>{` containing the JWK private key.`}</p>
    <p><strong parentName="p">{`--public-key`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The `}<inlineCode parentName="p">{`file`}</inlineCode>{` containing the JWK public key.`}</p>
    <p><strong parentName="p">{`--client-id`}</strong>{`=`}<inlineCode parentName="p">{`id`}</inlineCode>{`
The `}<inlineCode parentName="p">{`id`}</inlineCode>{` used to validate the audience in an OpenID Connect token.`}</p>
    <p><strong parentName="p">{`--client-secret`}</strong>{`=`}<inlineCode parentName="p">{`secret`}</inlineCode>{`
The `}<inlineCode parentName="p">{`secret`}</inlineCode>{` used to obtain the OpenID Connect tokens.`}</p>
    <p><strong parentName="p">{`--listen-address`}</strong>{`=`}<inlineCode parentName="p">{`address`}</inlineCode>{`
The callback `}<inlineCode parentName="p">{`address`}</inlineCode>{` used in the OpenID Connect flow (e.g. ":10000")`}</p>
    <p><strong parentName="p">{`--configuration-endpoint`}</strong>{`=`}<inlineCode parentName="p">{`url`}</inlineCode>{`
OpenID Connect configuration `}<inlineCode parentName="p">{`url`}</inlineCode>{`.`}</p>
    <p><strong parentName="p">{`--admin`}</strong>{`=`}<inlineCode parentName="p">{`email`}</inlineCode>{`
The `}<inlineCode parentName="p">{`email`}</inlineCode>{` of an admin user in an OpenID Connect provisioner, this user
will not have restrictions in the certificates to sign. Use the
'--admin' flag multiple times to configure multiple administrators.`}</p>
    <p><strong parentName="p">{`--remove-admin`}</strong>{`=`}<inlineCode parentName="p">{`email`}</inlineCode>{`
Remove the `}<inlineCode parentName="p">{`email`}</inlineCode>{` of an admin user in an OpenID Connect provisioner, this user
will not have restrictions in the certificates to sign. Use the
'--admin' flag multiple times to configure multiple administrators.`}</p>
    <p><strong parentName="p">{`--group`}</strong>{`=`}<inlineCode parentName="p">{`group`}</inlineCode>{`
The `}<inlineCode parentName="p">{`group`}</inlineCode>{` list used to validate the groups extenstion in an OpenID Connect token.
Use the '--group' flag multiple times to configure multiple groups.`}</p>
    <p><strong parentName="p">{`--tenant-id`}</strong>{`=`}<inlineCode parentName="p">{`tenant-id`}</inlineCode>{`
The `}<inlineCode parentName="p">{`tenant-id`}</inlineCode>{` used to replace the templatized {tenantid} in the OpenID Configuration.`}</p>
    <p><strong parentName="p">{`--x5c-root`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
Root certificate (chain) `}<inlineCode parentName="p">{`file`}</inlineCode>{` used to validate the signature on X5C
provisioning tokens.`}</p>
    <p><strong parentName="p">{`--nebula-root`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
Root certificate (chain) `}<inlineCode parentName="p">{`file`}</inlineCode>{` used to validate the signature on Nebula
provisioning tokens.`}</p>
    <p><strong parentName="p">{`--force-cn`}</strong>{`
Always set the common name in provisioned certificates.`}</p>
    <p><strong parentName="p">{`--require-eab`}</strong>{`
Require (and enable) External Account Binding for Account creation.`}</p>
    <p><strong parentName="p">{`--disable-eab`}</strong>{`
Disable External Account Binding for Account creation.`}</p>
    <p><strong parentName="p">{`--challenge`}</strong>{`=`}<inlineCode parentName="p">{`challenge`}</inlineCode>{`
The SCEP `}<inlineCode parentName="p">{`challenge`}</inlineCode>{` to use as a shared secret between a client and the CA`}</p>
    <p><strong parentName="p">{`--capabilities`}</strong>{`=`}<inlineCode parentName="p">{`capabilities`}</inlineCode>{`
The SCEP `}<inlineCode parentName="p">{`capabilities`}</inlineCode>{` to advertise`}</p>
    <p><strong parentName="p">{`--include-root`}</strong>{`
Include the CA root certificate in the SCEP CA certificate chain`}</p>
    <p><strong parentName="p">{`--min-public-key-length`}</strong>{`=`}<inlineCode parentName="p">{`length`}</inlineCode>{`
The minimum public key `}<inlineCode parentName="p">{`length`}</inlineCode>{` of the SCEP RSA encryption key`}</p>
    <p><strong parentName="p">{`--encryption-algorithm-identifier`}</strong>{`=`}<inlineCode parentName="p">{`id`}</inlineCode>{`
The `}<inlineCode parentName="p">{`id`}</inlineCode>{` for the SCEP encryption algorithm to use.
Valid values are 0 - 4, inclusive. The values correspond to:
0: DES-CBC,
1: AES-128-CBC,
2: AES-256-CBC,
3: AES-128-GCM,
4: AES-256-GCM.
Defaults to DES-CBC (0) for legacy clients.`}</p>
    <p><strong parentName="p">{`--aws-account`}</strong>{`=`}<inlineCode parentName="p">{`id`}</inlineCode>{`
The AWS account `}<inlineCode parentName="p">{`id`}</inlineCode>{` used to validate the identity documents.
Use the flag multiple times to configure multiple accounts.`}</p>
    <p><strong parentName="p">{`--remove-aws-account`}</strong>{`=`}<inlineCode parentName="p">{`id`}</inlineCode>{`
Remove an AWS account `}<inlineCode parentName="p">{`id`}</inlineCode>{` used to validate the identity documents.
Use the flag multiple times to remove multiple accounts.`}</p>
    <p><strong parentName="p">{`--azure-tenant`}</strong>{`=`}<inlineCode parentName="p">{`id`}</inlineCode>{`
The Microsoft Azure tenant `}<inlineCode parentName="p">{`id`}</inlineCode>{` used to validate the identity tokens.`}</p>
    <p><strong parentName="p">{`--azure-resource-group`}</strong>{`=`}<inlineCode parentName="p">{`name`}</inlineCode>{`
The Microsoft Azure resource group `}<inlineCode parentName="p">{`name`}</inlineCode>{` used to validate the identity tokens.
Use the flag multiple times to configure multiple resource groups`}</p>
    <p><strong parentName="p">{`--remove-azure-resource-group`}</strong>{`=`}<inlineCode parentName="p">{`name`}</inlineCode>{`
Remove a Microsoft Azure resource group `}<inlineCode parentName="p">{`name`}</inlineCode>{` used to validate the identity tokens.
Use the flag multiple times to configure multiple resource groups`}</p>
    <p><strong parentName="p">{`--azure-subscription-id`}</strong>{`=`}<inlineCode parentName="p">{`id`}</inlineCode>{`
The Microsoft Azure subscription `}<inlineCode parentName="p">{`id`}</inlineCode>{` used to validate the identity tokens.
Use the flag multiple times to configure multiple subscription IDs`}</p>
    <p><strong parentName="p">{`--remove-azure-subscription-id`}</strong>{`=`}<inlineCode parentName="p">{`id`}</inlineCode>{`
Remove a Microsoft Azure subscription `}<inlineCode parentName="p">{`id`}</inlineCode>{` used to validate the identity tokens.
Use the flag multiple times to configure multiple subscription IDs`}</p>
    <p><strong parentName="p">{`--azure-object-id`}</strong>{`=`}<inlineCode parentName="p">{`id`}</inlineCode>{`
The Microsoft Azure AD object `}<inlineCode parentName="p">{`id`}</inlineCode>{` used to validate the identity tokens.
Use the flag multiple times to configure multiple object IDs`}</p>
    <p><strong parentName="p">{`--remove-azure-object-id`}</strong>{`=`}<inlineCode parentName="p">{`id`}</inlineCode>{`
Remove a Microsoft Azure AD object `}<inlineCode parentName="p">{`id`}</inlineCode>{` used to validate the identity tokens.
Use the flag multiple times to configure multiple object IDs`}</p>
    <p><strong parentName="p">{`--gcp-service-account`}</strong>{`=`}<inlineCode parentName="p">{`email`}</inlineCode>{`
The Google service account `}<inlineCode parentName="p">{`email`}</inlineCode>{` or `}<inlineCode parentName="p">{`id`}</inlineCode>{` used to validate the identity tokens.
Use the flag multiple times to configure multiple service accounts.`}</p>
    <p><strong parentName="p">{`--remove-gcp-service-account`}</strong>{`=`}<inlineCode parentName="p">{`email`}</inlineCode>{`
Remove a Google service account `}<inlineCode parentName="p">{`email`}</inlineCode>{` or `}<inlineCode parentName="p">{`id`}</inlineCode>{` used to validate the identity tokens.
Use the flag multiple times to configure multiple service accounts.`}</p>
    <p><strong parentName="p">{`--gcp-project`}</strong>{`=`}<inlineCode parentName="p">{`id`}</inlineCode>{`
The Google project `}<inlineCode parentName="p">{`id`}</inlineCode>{` used to validate the identity tokens.
Use the flag multiple times to configure multiple projects`}</p>
    <p><strong parentName="p">{`--remove-gcp-project`}</strong>{`=`}<inlineCode parentName="p">{`id`}</inlineCode>{`
Remove a Google project `}<inlineCode parentName="p">{`id`}</inlineCode>{` used to validate the identity tokens.
Use the flag multiple times to configure multiple projects`}</p>
    <p><strong parentName="p">{`--instance-age`}</strong>{`=`}<inlineCode parentName="p">{`duration`}</inlineCode>{`
The maximum `}<inlineCode parentName="p">{`duration`}</inlineCode>{` to grant a certificate in AWS and GCP provisioners.
A `}<inlineCode parentName="p">{`duration`}</inlineCode>{` is sequence of decimal numbers, each with optional fraction and a
unit suffix, such as "300ms", "-1.5h" or "2h45m". Valid time units are "ns",
"us" (or "µs"), "ms", "s", "m", "h".`}</p>
    <p><strong parentName="p">{`--iid-roots`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The `}<inlineCode parentName="p">{`file`}</inlineCode>{` containing the certificates used to validate the
instance identity documents in AWS.`}</p>
    <p><strong parentName="p">{`--disable-custom-sans`}</strong>{`
On cloud provisioners, if enabled only the internal DNS and IP will be added as a SAN.
By default it will accept any SAN in the CSR.`}</p>
    <p><strong parentName="p">{`--disable-trust-on-first-use`}</strong>{`, `}<strong parentName="p">{`--disable-tofu`}</strong>{`
On cloud provisioners, if enabled multiple sign request for this provisioner
with the same instance will be accepted. By default only the first request
will be accepted.`}</p>
    <p><strong parentName="p">{`--admin-cert`}</strong>{`=`}<inlineCode parentName="p">{`chain`}</inlineCode>{`
Admin certificate (`}<inlineCode parentName="p">{`chain`}</inlineCode>{`) in PEM format to store in the 'x5c' header of a JWT.`}</p>
    <p><strong parentName="p">{`--admin-key`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
Private key `}<inlineCode parentName="p">{`file`}</inlineCode>{`, used to sign a JWT, corresponding to the admin certificate that will
be stored in the 'x5c' header.`}</p>
    <p><strong parentName="p">{`--admin-provisioner`}</strong>{`=`}<inlineCode parentName="p">{`name`}</inlineCode>{`, `}<strong parentName="p">{`--admin-issuer`}</strong>{`=`}<inlineCode parentName="p">{`name`}</inlineCode>{`
The provisioner `}<inlineCode parentName="p">{`name`}</inlineCode>{` to use for generating admin credentials.`}</p>
    <p><strong parentName="p">{`--admin-subject`}</strong>{`=`}<inlineCode parentName="p">{`subject`}</inlineCode>{`, `}<strong parentName="p">{`--admin-name`}</strong>{`=`}<inlineCode parentName="p">{`subject`}</inlineCode>{`
The admin `}<inlineCode parentName="p">{`subject`}</inlineCode>{` to use for generating admin credentials.`}</p>
    <p><strong parentName="p">{`--password-file`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The path to the `}<inlineCode parentName="p">{`file`}</inlineCode>{` containing the password to encrypt or decrypt the private key.`}</p>
    <p><strong parentName="p">{`--ca-url`}</strong>{`=`}<inlineCode parentName="p">{`URI`}</inlineCode>{`
`}<inlineCode parentName="p">{`URI`}</inlineCode>{` of the targeted Step Certificate Authority.`}</p>
    <p><strong parentName="p">{`--root`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The path to the PEM `}<inlineCode parentName="p">{`file`}</inlineCode>{` used as the root certificate authority.`}</p>
    <p><strong parentName="p">{`--context`}</strong>{`=`}<inlineCode parentName="p">{`name`}</inlineCode>{`
The context `}<inlineCode parentName="p">{`name`}</inlineCode>{` to apply for the given command.`}</p>
    <h2>{`Examples`}</h2>
    <p>{`Update a JWK provisioner with newly generated keys and a template for x509 certificates:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step beta ca provisioner update cicd --create --x509-template ./templates/example.tpl
`}</code></pre>
    <p>{`Update a JWK provisioner with duration claims:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step beta ca provisioner update cicd --create --x509-min-dur 20m --x509-default-dur 48h --ssh-user-min-dur 17m --ssh-host-default-dur 16h
`}</code></pre>
    <p>{`Update a JWK provisioner with existing keys:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step beta ca provisioner update jane@doe.com --public-key jwk.pub --private-key jwk.priv
`}</code></pre>
    <p>{`Update a JWK provisioner to disable ssh provisioning:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step beta ca provisioner update cicd --ssh=false
`}</code></pre>
    <p>{`Update an OIDC provisioner:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step beta ca provisioner update Google \\
  --configuration-endpoint https://accounts.google.com/.well-known/openid-configuration
`}</code></pre>
    <p>{`Update an X5C provisioner:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step beta ca provisioner update x5c --x5c-root x5c_ca.crt
`}</code></pre>
    <p>{`Update an ACME provisioner:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step beta ca provisioner update acme --force-cn --require-eab
`}</code></pre>
    <p>{`Update an K8SSA provisioner:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step beta ca provisioner update kube --public-key key.pub --x509-min-duration 30m
`}</code></pre>
    <p>{`Update an Azure provisioner:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step beta ca provisioner update Azure \\
  --azure-resource-group identity --azure-resource-group accounting
`}</code></pre>
    <p>{`Update a GCP provisioner:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step beta ca provisioner update Google \\
  --disable-custom-sans --gcp-project internal --remove-gcp-project public
`}</code></pre>
    <p>{`Update an AWS provisioner:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step beta ca provisioner update Amazon --disable-custom-sans --disable-trust-on-first-use
`}</code></pre>
    <p>{`Update a SCEP provisioner:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step beta ca provisioner update my_scep_provisioner --force-cn
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      